html,
body {
  height: 100%;
  min-height: 100%;
  background: #fff !important;
}

/* .MuiPaper-root p,
.MuiPaper-root span,
.MuiPaper-root input,
.MuiPaper-root button {
  font-size: 0.25rem !important;
} */

.menu-rotate-open {
  animation: menu-open 250ms linear forwards;
}

.menu-rotate-close {
  animation: menu-close 250ms linear forwards;
}

@keyframes menu-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes menu-close {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.deposit-text-animation {
  animation: deposit-blink 1.5s ease 0s infinite normal none running;
}

@keyframes deposit-blink {
  0% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  25% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  50% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  75% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  100% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
}

.share-button {
  animation: share-rotate 6s linear infinite;
}

@keyframes share-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  100% {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}

.container-no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container-no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.alert-pulse {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.marquee {
  height: 38px;
  overflow: hidden;
  position: relative;
}

.marquee p {
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 38px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 450px) {
  .header-logo {
    height: 7vw !important;
  }
}

.social-con > div {
  width: 218px;
}

.social-con button {
  margin: 0 !important;
  border-radius: 20px !important;
  padding: 9px 9px 9px 15px !important;
  height: auto !important;
  font-size: 16px !important;
  width: 218px !important;
}

.MuiDialogActions-root .MuiButton-root {
  color: #fff !important;
}

.telegram-btn {
  visibility: hidden;
  max-width: 0;
  margin-left: 0px !important;
}

.rainbow-box::before {
  margin: -5px;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 7px;
  background: linear-gradient(
    135deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.rhap_main-controls {
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0 !important;
  flex: unset !important;
  justify-content: center !important;
}

.rhap_additional-controls {
  flex: unset !important;
}

.rhap_play-pause-button,
.rhap_skip-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rhap_main-controls-button {
  font-size: unset !important;
  flex: 1;
}

.rhap_container {
  background-color: green !important;
  border-radius: 10px !important;
  padding: 0px 15px !important;
}

.rhap_volume-controls {
  display: none !important;
}

.rhap_progress-section {
  display: none !important;
}

.page-maxwidth {
  max-width: 992px !important;
  margin-left: auto;
  margin-right: auto;
}

.mobile-nav-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  background: rgb(22, 28, 36);
  background: -moz-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: -webkit-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  z-index: 1199;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjust-bottom-space {
  bottom: 8% !important;
}

/* .standard-FS p, div, span, button {
  font-size: 0.25rem !important;
}

.standard-FS h4, svg {
  font-size: 0.5rem !important;
} */

.custom-daterange .DateRangePicker__Month {
  margin: 0px !important;
}

.DateRangePicker__CalendarSelection {
  background-color: #fff !important;
}
.DateRangePicker__CalendarSelection > span {
  color: #871f79 !important;
}

.DateRangePicker__Date {
  background-color: transparent !important;
  border: unset !important;
  color: #beabb8 !important;
}

.DateRangePicker__WeekdayHeading {
  border-bottom: unset !important;
}

.DateRangePicker__WeekdayHeading abbr[title] {
  color: #fff !important;
}

.DateRangePicker__MonthHeader {
  color: #fff !important;
}

.DateRangePicker__MonthDates {
  border-bottom: unset !important;
}

.custom-page:not(:empty) {
  color: #fff;
}
